import { useLayoutEffect, useState } from 'react';
import './Admin.css';
import Loading from '../Loading';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

export default function Accounts(){
    const [isLoading,setLoading]=useState(false)
    const [loadingAction,setLoadingAction]=useState("")
    const [accounts,setAccounts]=useState([])

    const getAccounts=()=>{
        setLoadingAction("Getting accounts...")
        setLoading(true);
        setTimeout(async() => {
            const res = await axios.get('https://ganesh.leadsoft.academy/APIS/getAccounts.php')
            const resData = res.data
            setLoading(false)
            if(resData.status==="success"){
                setAccounts(resData.data)
            }
        }, 200);
    }

    useLayoutEffect(()=>{
        getAccounts()
    },[])
    const editChange=(e)=>{
        if(e.target.value==='') getAccounts()
        const filteredAccounts = accounts.filter((account) =>
            account.GroupedParticular.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setAccounts(accounts=>filteredAccounts)
    }

    return(
        <>
            {
                isLoading &&
                <Loading message={loadingAction}/>
            }

            <div className="account-container">
                <div className="filter-container">
                    <label>Ledger Name</label>
                    <input type='text' onChange={editChange} />
                </div>
                <table style={{width:"80%"}}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ledger</th>
                            <th>Debit (Rs.)</th>
                            <th>Credit (Rs.)</th>
                            <th>Entry Count</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        accounts.length > 0 ? (
                            accounts.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{item.GroupedParticular}</td>
                                        <td>{Number(item.TotalDebitAmt).toFixed(2)}</td>
                                        <td>{Number(item.TotalCreditAmt).toFixed(2)}</td>
                                        <td>{item.EntryCount}</td>
                                        <td >
                                            <NavLink to={`/view-statement/${item.GroupedParticular}`}>
                                                View Statement
                                            </NavLink>
                                        </td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr>
                                <td colSpan='5' style={{color:"red"}}>No Accounts found !!</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}