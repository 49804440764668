import { useLayoutEffect, useState } from 'react';
import './Admin.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loading from '../Loading';

export default function ViewAccounts(){
    const [statements,setStatements]=useState([])
    const [isLoading,setLoading]=useState(false)
    const [loadingAction,setLoadingAction]=useState("")
    const [totalDebit,setTotalDebit]=useState(0)
    const [totalCredit,setTotalCredit]=useState(0)

    const {ledgerName}=useParams()
    const getAccountStatement=()=>{
        setLoadingAction("Getting statements...")
        setLoading(true)
        setTimeout(async() => {
            const res = await axios.get(`https://ganesh.leadsoft.academy/APIS/getAccountLedgers.php?ledgerName=${ledgerName}`)
            const resData = res.data
            setLoading(false)
            if(resData.status==='success'){
                setStatements(resData.data)
                calculate(resData.data)
            }
        }, 200);
    }
    const calculate=(data)=>{
        let temp1=0,temp2=0;
        for(const d of data){
            temp1+= Number(d.CreditAmt)
            temp2+= Number(d.DebitAmt)
        }
        setTotalCredit(temp1)
        setTotalDebit(temp2)
    }
    useLayoutEffect(()=>{
        getAccountStatement()
    },[])

    return(
        <>
            {
                isLoading &&
                <Loading message={loadingAction}/>
            }       
            <div className="account-container">
                <div className="title-container">
                    <h2>Account Ledgers | {ledgerName}</h2>
                    <div className='balance'>
                        <div className="field">
                            <label>Balance (Rs.) :</label>
                            <p style={{color: Number(totalCredit-totalDebit)<0 ? 'red':''}}>
                                Rs. {Number(Math.abs(totalCredit-totalDebit)).toFixed(2)}
                                { Number(totalCredit-totalDebit)<0 ? ' Cr' : ' Dr' }
                            </p>
                        </div>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Particular</th>
                            <th>Credit (Rs.)</th>
                            <th>Debit (Rs.)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            statements.length > 0 ? (
                                <>
                                {statements.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.Particular}</td>
                                            <td>{Number(item.CreditAmt).toFixed(2)}</td>
                                            <td>{Number(item.DebitAmt).toFixed(2)}</td>    
                                        </tr>                                    
                                    )
                                })}
                                <tr style={{fontWeight:"550"}}>
                                    <td colSpan="2"></td>
                                    <td style={{color:"crimson"}}>{Number(totalCredit).toFixed(2)}</td>
                                    <td style={{color:"darkgreen"}}>{Number(totalDebit).toFixed(2)}</td>
                                </tr>
                                </>
                            ) : 
                            (
                                <tr>
                                    <td colSpan="4">Loading...</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}